.firstPhoto {
  background-image: url("cover.jpg");
}

.tablePhoto {
  background-image: url("table4.jpg");
}

.bridesmaid {
  background-image: url("bridemaids.jpg");
}
