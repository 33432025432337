.link {
  color: #2b2b2b;
}
.link:hover {
  color: #d69460;
}
.mobile-screen-nav {
  background-color: "#FDF7F2";
}
@media only screen and (max-width: 600px) {
  .full-screen-nav {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .mobile-screen-nav {
    display: none;
    background-color: "#FDF7F2";
  }
}
